<template>
  <div class="check-type flex-column">
    <div class="nameTit flex-space-between">
      <div class="tp-left flex-center-v">
        <span>检查类型</span>
        <el-button type="primary" size="mini" plain style="margin-left: 20px" @click="showFormTank" v-if="isCompany&&(isTissueAdmin||isAdmin)">新增检查类型</el-button>
      </div>
      <div class="tp-right flex-center-v">
        <el-input v-model="searchData.name" placeholder="检查类型名称" clearable size="small" style="margin-right:15px;"></el-input>
        <el-button type="primary" size="small" style="width:80px;" @click="search">查询</el-button>
      </div>
    </div>
    <div class="cont flex-1-auto">
      <el-table ref="multipleTable" :data="tableList" height="100%" style="width: 100%" tooltip-effect="light myTooltips">
        <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column prop="name" label="检查类型名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="explains" label="检查类型说明" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.status ? '启用中' : '作废中' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-if="isCompany&&(isTissueAdmin||isAdmin)">
          <template slot-scope="{ row }">
            <el-button type="text" size="mini" @click="edit(row)">编辑</el-button>
            <el-button v-if="!row.status" type="text" size="mini" @click="enableOrDisable(row, 'star')">启用</el-button>
            <el-button v-else type="text" size="mini" @click="enableOrDisable(row, 'end')">作废</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex-center" style="padding-top: 10px">
      <el-pagination
          v-if="total > searchData.pageSize"
          background
          :current-page.sync="searchData.pageCurrent"
          :page-size="searchData.pageSize"
          :total="total"
          layout="total, prev, pager, next"
          @current-change="handleSizeChangeData"
      ></el-pagination>
    </div>
    <el-dialog :title="title" :visible.sync="showTank" width="30%" :close-on-click-modal="false" @close="close">
      <el-form ref="form" :model="formData" :rules="rules" class="demo-form-inline" label-width="110px">
        <el-form-item label="检查类型名称" prop="name">
          <el-input v-model="formData.name" placeholder="请输入检查类型名称"></el-input>
        </el-form-item>
        <el-form-item label="检查类型说明" prop="explains">
          <el-input type="textarea" placeholder="请输入检查类型说明" v-model="formData.explains" :rows="4" maxlength="255" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="save">确 定</el-button>
        <el-button size="small" @click="showTank = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import apis from './apis'
import {mapGetters} from "vuex";

export default {
  name: "check-type",

  components: {},

  props: {},

  data() {
    return {
      title: '新增检查类型',
      showTank: false,
      formData: {},
      rules: {
        name: [
          { required: true, message: '请输入检查类型名称', trigger: 'blur' },
        ],
        explains: [
          { required: false, message: '请输入检查类型说明', trigger: 'blur' },
        ]
      },
      tableList: [],
      searchData: {
        pageCurrent: 1,
        pageSize: 10
      },
      total: 0
    }
  },

  computed: {
    ...mapGetters(['companyId','optionItem']),
    isTissueAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("组织管理员");
    },
    isAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("超级管理员")
    },
    // 是否为集团人员
    isCompany(){
      return this.optionItem && this.optionItem.departmentType == 'company';
    },
  },

  watch: {
    companyId() {
      this.search()
    }
  },

  mounted() {
    if(this.companyId) this.getTableList()
  },

  methods: {
    async getTableList() {
      const result = await apis.pageCheckType(this.searchData)
      this.tableList = result.list
      this.total = result.total
    },
    showFormTank() {
      this.title = '新增检查类型'
      this.formData.parentId = 0
      this.isEdit = false
      this.showTank = true
    },
    close() {
      this.formData = {}
      this.$refs.form.resetFields()
    },
    save() {
      this.$refs.form.validate(async validate => {
        if (validate) {
          if(this.isEdit) await apis.editCheckType(this.formData)
          else await apis.addCheckType(this.formData)
          this.$message.success('操作成功')
          this.showTank = false
          this.getTableList().async
        }
      })
    },
    add(data) {
      this.formData.parentId = data.id
      this.title = '新增检查类型'
      this.isEdit = false
      this.showTank = true
    },
    edit(data) {
      this.title = '编辑检查类型'
      this.formData = JSON.parse(JSON.stringify(data))
      this.isEdit = true
      this.showTank = true
    },
    enableOrDisable(data, status) {
      const title = status == 'star' ? '是否启用该检查类型' : '是否作废该检查类型'
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        if (status == 'star') await apis.enableCheckType(data.id)
        else await apis.disableCheckType(data.id)
        this.$message.success('操作成功')
        // this.init().async
        this.getTableList().async
      })
    },
    // 查询
    search() {
      this.searchData.pageCurrent = 1
      this.getTableList().async
    },
    // 切换页码
    async handleSizeChangeData(val) {
      this.searchData.pageCurrent = val
      this.getTableList().async
    }
  }
}
</script>

<style lang="stylus" scoped>
.check-type {
  height 100%
  padding 10px
}
</style>
